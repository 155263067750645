<template>
  <section :style="HeightVar" :show="loading" class="ll-wrapper">
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
    >
      <b-card-header>
        <b-card-title>{{ $t('Destination Location') }}</b-card-title>
        <b-button
          v-b-toggle.sidebar
          id="popover-reactive-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          v-permission="[136]"
          v-if="editRouting.id !== null"
          @click="onClear()"
        >
          <span>{{ $t('Edit') }}</span>
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="4">
            <div class="ll-map" ref="map" :style="{'height': height+'px'}"></div>
          </b-col>
          <b-col lg="4">
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Service Party') }}:</label>
              <span class="ll-span" v-if="imgData !== '' && imgData !== null">
                <b-img
                  :src="imgData"
                  height="auto"
                  class="mr-1 ll-img"
                />
              </span>
              <span class="ll-span" v-else>{{sParty}}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('3rd Party Ref No') }}:</label>
              <span class="ll-span">{{ refNo }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Delivery Address') }}:</label>
              <span class="ll-span">{{ (jobBaseInfo.updAddress !== null) ? (jobBaseInfo.updAddress) : caddress}}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Remark') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.updRemark}}</span>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="ll-box">
              <label class="ll-lab">{{ $t('District') }}:</label>
              <span class="ll-span">{{ cdistinct }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Sorting Code') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.sortCode }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('SC Sorting Code') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.scSortCode }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('SC Sorting Code(footer)') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.scSortCode2 }}</span>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
      v-if="false"
    >
      <b-card-header>
        <b-card-title class="ll-title">{{ $t('Shipment status tracking') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cb">
        <b-table :items="items" :fields="fields"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-tableHei"
        >
          <template #cell(statusType)="scope">
            <feather-icon
              :icon="(scope.value==='NORMAL') ? icon1 : icon2"
              size="16"
              :class="(scope.value==='NORMAL') ? color1 : color2"
            />
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <!-- SLA -->
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
    >
      <b-card-header>
        <b-card-title class="ll-title">{{ $t('SLA Tracking') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cb">
        <b-table :items="slaItems" :fields="slaFields"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-tHei"
        >
          <template #cell(slaStatus)="scope">
            <b-badge
              pill
              class="ll-badge"
              :variant="(scope.value.trim() === 'PASSED') ? 'light-info' : 'light-danger'">
              <span>{{ scope.value }}</span>
            </b-badge>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <!-- charges -->
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
    >
      <b-card-header>
        <b-card-title class="ll-title">{{ $t('Charges') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cb">
        <b-table :items="citems" :fields="cfields"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-tHei"
        >
          <template #cell(statusType)="scope">
            <feather-icon
              :icon="(scope.value==='NORMAL') ? icon1 : icon2"
              size="16"
              :class="(scope.value==='NORMAL') ? color1 : color2"
            />
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!-- routeing sidebar -->
    <b-sidebar id="sidebar" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2 ll-py">
        <validation-observer ref="rules">
          <b-tabs
            class="ll-tabs"
            fill
          >
            <b-tab title="UPDATE SORT CODE" active>
          <!-- <app-collapse>
            <app-collapse-item :title="$t('Update')+$t('Sort Code')"> -->
              <div class="ll-checkbox">
                <b-row>
                  <b-col lg="4">
                    <span>{{ $t('Home delivery') }}</span>
                  </b-col>
                  <b-col lg="4">
                    <b-form-checkbox
                      checked="true"
                      class="custom-control-info ll-switch"
                      name="check-button"
                      switch
                      v-model="ischeck"
                      @input="selhd"
                    />
                  </b-col>
                  <b-col lg="4">
                    <span>{{ $t('Self-pick') }}</span>
                  </b-col>
                </b-row>
              </div>
              <b-form-group
                :label="$t('Select a delivery area')"
                label-for="area"
                v-if="ishd"
              >
                <validation-provider
                  #default="{ errors }"
                  name="area"
                  rules="required"
                >
                  <v-select
                    id="area"
                    :options="areaList"
                    label="routeArea"
                    v-model="area"
                    placeholder="Please select area"
                    @input="getArea"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a district')"
                label-for="district"
                v-if="isDis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                >
                  <v-select
                    id="district"
                    :options="districtList"
                    label="routeDistrict"
                    v-model="district"
                    placeholder="Please select district"
                    @input="getDistrict"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a routing code')"
                label-for="routeCode"
                v-if="isRcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="routeCode"
                  rules="required"
                >
                  <v-select
                    id="routeCode"
                    :options="routeCodeList"
                    label="sortingCode"
                    v-model="editRouting.sortCode"
                    placeholder="Please select routing code"
                    @input="getRouteCode"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a vendor')"
                label-for="vendor"
                v-if="issp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="vendor"
                  rules="required"
                >
                  <v-select
                    id="vendor"
                    :options="vendorList"
                    label="agent"
                    v-model="vendor"
                    placeholder="Please select vendor"
                    @input="getVendor"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Location code')"
                label-for="district"
                v-if="isLocCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location code"
                  rules="required"
                >
                  <v-select :options="locCodeList" label="locationID" @input="selCode" v-model="locCode"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Location name')"
                label-for="area"
                v-if="isLocName"
              >
                <b-form-input v-model="locName"/>
              </b-form-group>
              <div class="ll-btn">
              <b-button
                variant="secondary"
                class="mr-1"
                @click="onClear"
                style="width:200px"
              >
                {{ $t('Clear') }}
              </b-button>
              <b-button
                v-b-toggle.sidebar
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click.prevent="checkRouting"
                style="width:200px"
              >
                {{ $t('Save') }}
              </b-button>
            </div>
            </b-tab>
            <b-tab title="UPDATE CONSIGNEE INFORMATION">
            <!-- <app-collapse-item :title="$t('Update Consignee Address')"> -->
              <b-card-title style="margin-bottom: 2rem;">{{ $t('Update') }} {{ $t('Consignee Address') }}</b-card-title>
              <b-form-group
                :label="$t('New Address')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updAddress"/>
              </b-form-group>
              <b-form-group
                :label="$t('Remark (Optional)')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updRemark"/>
              </b-form-group>
              <b-card-title style="margin-bottom: 2rem;">{{ $t('Update') }} {{ $t('Consignee Phone') }}</b-card-title>
              <b-form-group
                :label="$t('New Phone')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.cneephone"/>
              </b-form-group>
            <!-- </app-collapse-item> -->
          <!-- </app-collapse> -->
              <div class="ll-btn">
                <b-button
                  variant="secondary"
                  class="mr-1"
                  @click="onClear"
                  style="width:200px"
                >
                  {{ $t('Clear') }}
                </b-button>
                <b-button
                  v-b-toggle.sidebar
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click.prevent="consigneeSave"
                  style="width:200px"
                >
                  {{ $t('Save') }}
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BRow, BCol, BImg,
  BSidebar, VBToggle, BButton, BFormGroup, BFormInput, BCardTitle,
  BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BImg,
    BSidebar,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    permission,
  },
  props: {
    // tkid: String,
    // orderId: String,
    heiVal: Number,
    jobDetails1: Object,
    jobDetails2: Object,
    jobDetails3: Array,
    jobDetails5: Array,
    jobDetails6: Array,
  },
  data() {
    return {
      required,
      items: [],
      fields: [
        { key: 'statusType', label: '' },
        { key: 'updatedAt', label: 'Time Updated' },
        { key: 'statusCode', label: 'StatusNo' },
        { key: 'statusDesc', label: 'Description' },
        { key: 'originStatusCode', label: 'External Code' },
        { key: 'updateSource', label: 'Source' },
        { key: 'operator', label: 'Operator' },
        { key: 'refTkID', label: 'Ref No' },
      ],
      areaList: [],
      area: '',
      districtList: [],
      district: '',
      routeCodeList: [],
      editRouting: {
        id: null,
        sortCode: null,
        updRemark: null,
        updAddress: null,
        isSelfPick: false,
        locationID: null,
        cneephone:null
      },
      sParty: '',
      imgData: '',
      longitude: 114.132294,
      latitude: 22.360371,
      jobBaseInfo: {
        tkID: '',
        latestOrderStatus: '',
        orderID: '',
        mfNo: '',
        sortCode: '',
        attempts: '',
        serviceParty: '',
        mawb: '',
        numberOfReturn: '',
        customerOrderNumber: '',
        pkShippingDate: '',
        ead: '',
        scSortCode: '',
        scSortCode2: null,
        latitude: null,
        longitude: null,
        latestOrderStatusTime: '',
        updAddress: '',
        updRemark: '',
      },
      condition: {
        page: '1',
        size: '10',
        trackingIDs: null,
        orderBy: 'id',
        orderMole: 1,
        isAmazon: true,
      },
      refNo: '',
      caddress: '',
      cdistinct: '',
      jobTrack: {
        page: '1',
        size: '20',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
      },
      icon1: 'CheckCircleIcon',
      icon2: 'AlertTriangleIcon',
      color1: 'icon-sussess',
      color2: 'icon-fail',
      slaItems: [],
      slaFields: [
        // { key: 'slaName', label: 'SLA ID' },
        { key: 'slaName', label: this.$t('SLA Name') },
        { key: 'startDatetime', label: this.$t('Clockstart') },
        { key: 'endDateTime', label: this.$t('Clockstop') },
        { key: 'expiredDateTime', label: this.$t('SLA Due') },
        { key: 'slaStatus', label: this.$t('Result') },
      ],
      citems: [],
      cfields: [
        { key: 'feeType', label: this.$t('Charge Code') },
        { key: 'amount', label: this.$t('Amount') },
        { key: 'updatedAt', label: this.$t('Date Charged') },
        { key: 'operator', label: this.$t('Charged By') },
        { key: 'invoiceNo', label: this.$t('Invoice No') },
        { key: 'invoiceType', label: this.$t('Invoice Type') },
      ],
      condition1: {
        page: '1',
        size: '15',
        orderBy: 'id',
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
      collapsed: true,
      isDis: false,
      isRcode: false,
      tHei: document.documentElement.clientHeight*0.06,
      ischeck: false,
      ishd: true,
      issp: false,
      vendorList: [],
      vendor: '',
      height: document.documentElement.clientHeight*0.2,
      wrpHei: document.documentElement.clientHeight*0.6,
      locCodeList: [],
      locName: null,
      isLocCode: false,
      isLocName: false,
      loading:false,
      tkID:'',
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.tHei + 'px'
      }
    },
    HeightVar() {
      return {
        '--height1': this.wrpHei + 'px'
      }
    }
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
    },
    jobDetails2(val){
      this.getinfo2(val)
    },
    jobDetails3(val){
      this.getinfo3(val)
    },
    jobDetails5(val){
      this.getinfo4(val)
    },
    jobDetails6(val){
      this.getinfo5(val)
    },
    // tkid(newVal) {
    //   this.getDetail(newVal)
    //   this.statusTrack(newVal)
    //   this.queryCharge(newVal)
    //   this.slaTrack(newVal)
    //   this.onClickSidebar()
    // },
    // orderId(val) {
    //   this.getDetail1(val)
    // },
    heiVal(val) {
      // console.log('val3', val)
      this.tHei = val*0.1
      this.wrpHei = val*0.8
      // if (val < 640) {
      //   this.tHei = val*0.1
      // } else {
      //   this.tHei = val*0.15
      // }
      // console.log('thei3',this.tHei)
    },
  },
  created() {},
  mounted() {
    console.info(this.jobDetails2,this.jobDetails1,'/*/')
    this.queryArea()
    // this.initMaps1()
    this.queryVendorList()
    this.getinfo1(this.jobDetails1)
    this.getinfo2(this.jobDetails2)
    this.getinfo3(this.jobDetails3)
    this.getinfo4(this.jobDetails5)
    this.getinfo5(this.jobDetails6)
  },
  methods: {
    getinfo1(info) {
      console.info(info,'0----')
      if (info) {
        this.caddress = info.caddress
        this.cdistinct = info.cdistinct
      }
    },
    getinfo2(info) {
      console.info(info,'02----')
      if (info) {
        this.editRouting.id = info.id
        this.jobBaseInfo = info
        this.refNo = info.refTkID
        this.tkID = info.tkID
        this.sParty = info.serviceParty
        this.jobBaseInfo.latitude = Number(info.latitude)
        this.jobBaseInfo.longitude = Number(info.longitude)
        this.initMaps()
        if (this.sParty !== null) {
          this.querySParty()
        }
        this.onClickSidebar()
      }
      
    },
    getinfo3(info) {
      if (info) {
        this.items = info
      }
    },
    getinfo4(info) {
      if (info) {
        this.slaItems = info
      }
    },
    getinfo5(info) {
      if (info) {
        this.citems = info
      }
    },
    // 基本信息-根据TKID查询
    getDetail(tkid) {
      this.condition.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobkes/search', this.condition).then(res => {
        const info = res.data.data.content[0]
        this.editRouting.id = info.id
        this.jobBaseInfo = info
        this.refNo = info.refTkID
        this.sParty = info.serviceParty
        this.jobBaseInfo.latitude = Number(info.latitude)
        this.jobBaseInfo.longitude = Number(info.longitude)
        this.initMaps()
        if (this.sParty !== null) {
          this.querySParty()
        }
      })
    },
    // 基本信息-根据orderId查询
    getDetail1(oId) {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.caddress = info.caddress
          this.cdistinct = info.cdistinct
        }
      }).catch(error => console.log(error))
    },
    // shipment status tracking
    statusTrack(tkid) {
      this.jobTrack.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', this.jobTrack).then(res => {
        this.items = res.data.data.content
      })
    },
    // SLA Tracking
    slaTrack(tkid) {
      const data = {}
      data.tkId = tkid
      this.$http.post('jobSla/search', data).then(res =>{
        this.slaItems = res.data.data
      })
    },
    // 地图
    initMaps1() {
      this.maps = new google.maps.Map(this.$refs.map, {
          zoom: 8,
          center: { lat: this.latitude, lng: this.longitude },
          disableDefaultUI: true,
          gestureHandling: 'greedy',
          language: 'zh_cn',
        })
        this.marker = new google.maps.Marker({
          position: { lat: this.latitude, lng: this.longitude },
          map: this.maps,
        })
    },
    initMaps() {
      if (this.jobBaseInfo.latitude === 0 || this.jobBaseInfo.longitude === 0) {
        this.initMaps1()
      } else {
        this.maps = new google.maps.Map(this.$refs.map, {
          zoom: 17,
          center: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          disableDefaultUI: true,
          gestureHandling: 'greedy',
          language: 'zh_cn',
        })
        this.marker = new google.maps.Marker({
          position: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          map: this.maps,
        })
      }
    },
    // routing 弹框
    queryArea() {
      this.$http.get('routeList/getRouteAreaList').then(res => {
        this.areaList = res.data.data
      })
    },
    getArea(s) {
      this.area = s.routeArea
      if (this.area !== null) {
        // this.queryDistrict()
        // this.isDis = true
        this.queryRouteCode()
        this.isRcode =true
      }
    },
    queryDistrict() {
      const data = {}
      data.routeArea = this.area
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.districtList = res.data.data
      })
    },
    getDistrict(s) {
      this.district = s.routeDistrict
      if (this.district !== null) {
        this.queryRouteCode()
        this.isRcode = true
      }
    },
    queryRouteCode() {
      const data = {}
      data.routeArea = this.area
      // data.routeDistrict = this.district
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.routeCodeList = res.data.data
      })
    },
    getRouteCode(s) {
      this.editRouting.sortCode = s.sortingCode
    },
    checkRouting1() {
      return new Promise((resolve, reject) => {
        this.$refs.rules.validate().then(success => {
          if (success) {
            this.saveRouting()
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please check the data',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            reject()
          }
        })
      })
    },
    checkRouting() {
      if (this.editRouting.updAddress === '') {
        this.checkRouting1()
      } else {
        this.saveRouting()
      }
    },
    consigneeSave(){
      if (this.editRouting.cneephone&&this.editRouting.cneephone.length < 8) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '手機號的輸入要大於8位',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return false;
      }
      let action = []
      if (this.editRouting.cneephone && this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_PHONE","UPDATE_REMARK"]
      }else if (!this.editRouting.cneephone && this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_REMARK"]
      }else if (this.editRouting.cneephone && !this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_PHONE","UPDATE_REMARK"]
      }else if (this.editRouting.cneephone && this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_PHONE"]
      }else if (!this.editRouting.cneephone && !this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_REMARK"]
      }else if (!this.editRouting.cneephone && this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS"]
      }else if (this.editRouting.cneephone && !this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_PHONE"]
      }
      let params = {
        tkID:this.tkID,
        address:this.editRouting.updAddress,
        phone:this.editRouting.cneephone,
        remark:this.editRouting.updRemark,
        action:action
      }
      this.$http.post('jobkes/editOrderInfo', params).then(res =>{
        // console.log(res.data)
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
      this.sidebar = false
    },
    onClear() {
      this.editRouting.updRemark = ''
      this.editRouting.updAddress = ''
      this.editRouting.sortCode = ''
      this.editRouting.cneephone = ''
      this.area = ''
      this.district = ''
    },
    editRoutingCode() {
      if (!this.editRouting.isSelfPick) {
        delete this.editRouting.serviceParty
      }
      this.$http.put('jobkes/editRoutingCode', this.editRouting).then(res =>{
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    saveRouting() {
      if (this.editRouting.id !== null) {
        if (!this.editRouting.isSelfPick) {
          delete this.editRouting.serviceParty
        }
        this.editRoutingCode()
        // if (this.deliveryType === 'SP' && !this.editRouting.isSelfPick) {
        //   this.$swal({
        //     title: '',
        //     text: this.$t('Are you sure to change self-pick to Home delivery'),
        //     icon: 'warning',
        //     confirmButtonText: 'Sure',
        //     showCancelButton: true,
        //     customClass: {
        //       confirmButton: 'btn btn-primary',
        //       cancelButton: 'btn btn-outline-danger ml-1',
        //     },
        //     buttonsStyling: false,
        //   }).then(result => {
        //     if (result.isConfirmed) {
        //       this.editRoutingCode()
        //     }
        //   })
        // } else {
        //   this.editRoutingCode()
        // }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    querySParty() {
      this.$http.get('admin/settings/organization/getByName', { params: { name: this.sParty}}).then(res=> {
        this.imgData = res.data.data
      })
    },
    // charges table
    queryCharge(tkid) {
      this.condition1.refNo = tkid
      this.$http.post('invoiceDetail/search', this.condition1).then(res => {
        this.citems = res.data.data.content
      })
    },
    selhd(d) {
      this.editRouting.isSelfPick = d
      if (d) {
        this.ishd = false
        this.isDis = false
        this.isRcode =false
        this.issp = true
      } else {
        this.ishd = true
        this.issp = false
        this.isLocCode = false
        this.isLocName = false
      }
    },
    queryVendorList() {
      this.$http.get('selfpickLocation/getAgentList').then(res=>{
        this.vendorList = res.data.data
      })
    },
    getVendor(d) {
      this.vendor = d.agent
      this.editRouting.serviceParty = d.agent
      this.locCode = null
      this.locName = null
      this.queryLocCode()
      if (this.vendor !== null) {
        this.queryLocCode()
        this.isLocCode = true
      }
    },
    queryLocCode() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.vendor}}).then(res=>{
        this.locCodeList = res.data.data
      })
    },
    selCode(d) {
      this.locCode = d.locationID
      this.editRouting.locationID = d.locationID
      if (this.locCode !== null) {
        this.queryLocName()
        this.isLocName = true
      }
    },
    queryLocName() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.pro, locationID:this.locCode}}).then(res=>{
        this.locName = res.data.data[0].storename
      })
    },
    onClickSidebar(){
      // this.editRouting.sortCode = null
      this.editRouting.updRemark = null
      this.editRouting.updAddress = null
      // this.editRouting.isSelfPick = null
      // this.editRouting.locationID = null
    }
  },
}
</script>
<style scoped>
.ll-wrapper{
  height: var(--height1);
  overflow-y: auto;
}
.ll-c{
  margin-bottom: 10px;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.ll-map{
  width: 100%;
  /* height: 130px; */
  height: var(--height);
  margin-bottom: 10px;
}
.ll-box{
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 6px;
}
.ll-box .ll-lab{
  font-weight: 500;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-tableHei{
  height: 274px;
}
.ll-img{
  width: 80px;
  height: 25px;
}
.ll-tHei{
  /* height: 90px; */
  height: var(--height);
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-checkbox{
  margin-bottom: 2rem;
}
.ll-checkbox span{
  font-weight: bold;
}
</style>
<style>
.ll-switch .custom-control-label::before{
  width: 100px;
  height: 2rem;
  top: -0.1rem;
  background-color: #409eff;
}
[dir=ltr] .ll-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(6.4rem);
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
/* .ll-cardAct{
  border: 1px solid #eee;
} */
.ll-cardAct>.card{
  box-shadow: unset;
}
.ll-cardAct .card-header, .ll-cardAct .card-body{
  padding: 0;
  margin-bottom: 10px;
}
</style>

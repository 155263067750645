<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-flex flex-column">
      <!--
      <div class="bg-white d-flex">
        <div class="px-2 pt-2" style="width:25%">
          <p class="l-pCon">{{netWeight}}</p>
          <p class="l-pTit">{{ $t('Net weight') }}</p>
        </div>
        <div class="px-2 pt-2" style="width:25%">
          <p class="l-pCon">{{value}}</p>
          <p class="l-pTit">{{ $t('Value') }}</p>
        </div>
        <div class="px-2 pt-2" style="width:50%">
          <p class="l-pCon">{{demension}}</p>
          <p class="l-pTit">{{ $t('Demension') }} (L x W x H)</p>
        </div>
      </div>
      -->
      <div class="bg-white p-2" v-if="item1.length > 0">
        <b-table :items="item1" :fields="fields1"
          :borderless='false' :hover="true"
          :sticky-header="true"
          :style="styleVar">

          <template #cell(unitPrice)="scope">
            <span class="text-nowrap">{{ scope.item.currency }} {{ scope.item.unitPrice }}</span>
          </template>
        </b-table>
      </div>
      <div class="bg-white p-2 display" v-else>
        No item data
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
    BCard, BCardBody, BTable,
    BOverlay,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BOverlay,
  },
  props: {
    orderId: String,
    heiVal: Number,
    jobDetails1: Object,
    jobDetails4: Array,
  },
  data() {
    return {
      item1: [],
      fields1: [
        { key: 'productId', label: "ASIN", thStyle: { width:'100px'} },
        { key: 'title', label: this.$t('Item') },
        { key: 'quantity', label: this.$t('Quantity'), thStyle: { width:'80px'} },
        { key: 'unitPrice', label: this.$t('Unit Price'), thStyle: { width:'100px'} },
        { key: 'hsCode', label: this.$t('HSCode'), thStyle: { width:'100px'} },
        { key: 'origin', label: this.$t('Origin'), thStyle: { width:'80px'} },
      ],
      seqNo: null,
      netWeight: null,
      value: null,
      demension: null,
      pkSn: null,
      loading: false,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.heiVal + 'px'
      }
    },
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
    },
    jobDetails4(val){
      this.getinfo2(val)
    },
    // orderId(val) {
    //   this.getDetail(val)
    //   this.getDetail1(val)
    // },
  },
  created() {},
  methods: {
    getinfo1(v){
      const info = v.packages[0]
      this.value = (info.value !== null) ? info.value : 0
      if (info.length !== null || info.width !== null || info.height !== null) {
        this.demension = `${info.length} X ${info.width} X ${info.height} `
      } else {
        this.demension = '0 X 0 X 0'
      }
      this.netWeight = (info.actWeight !== null) ? info.actWeight : 0
      this.pkSn = (info.pkSn !== null) ? info.pkSn : 0
    },
    getinfo2(info){
      this.item1 = info
    },
    getDetail1(oId) {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        const info = res.data.data.packages[0]
        // console.log('res1', info)
        this.value = (info.value !== null) ? info.value : 0
        if (info.length !== null || info.width !== null || info.height !== null) {
          this.demension = `${info.length} X ${info.width} X ${info.height} `
        } else {
          this.demension = '0 X 0 X 0'
        }
        this.netWeight = (info.actWeight !== null) ? info.actWeight : 0
        this.pkSn = (info.pkSn !== null) ? info.pkSn : 0
      })
    },
    getDetail(oId) {
      this.loading = true
      this.$http.get('/jobkes/getPackageProducts', { params: { orderId: oId } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.item1 = info
        }
        this.loading = false
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.l-pCon{
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  margin-bottom: 0;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
  margin-bottom: 10px;
}
</style>

<template>
    <section>
        <!-- breadcrumb -->
        <div class="breadcrumb-top">
            <b-breadcrumb class="">
                <b-breadcrumb-item :to="{ name: 'dashboard' }" >
                    <feather-icon
                            icon="HomeIcon"
                            size="16"
                    />
                </b-breadcrumb-item>
                <b-breadcrumb-item>
                    {{ $t('Amazon Order') }}
                </b-breadcrumb-item>
                <b-breadcrumb-item active >
                    {{ $t('Order') }}
                </b-breadcrumb-item>
                <b-breadcrumb-item active v-if="tkid">
                    {{ tkid }}
                </b-breadcrumb-item>
            </b-breadcrumb>
        </div>

        <!-- first floor -->
        <div class="d-flex">
            <div style="width:400px;" class="mr-1">
                <b-card class="card-browser-states ll-fHei ll-card" style="height:160px;">
                    <b-card-body>
                        <flat-pickr
                                v-model="time"
                                class="form-control ll-fpickr"
                                :config="dateConfig"
                                @input="onChange"
                        />
                        <b-input-group class="ll-searchBox">
                            <b-input-group-prepend
                                    is-text
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    v-b-toggle.filterS
                                    class="ll-outline"
                                    :style="filterBg">
                                <!-- Filter -->
                                <svg class="ll-ficon" aria-hidden="true">
                                    <use xlink:href="#icon-filter"></use>
                                </svg>
                            </b-input-group-prepend>
                            <b-form-textarea
                                    v-model="trackingIDs"
                                    class="searchInput"
                                    :placeholder="$t('Enter Tracking Number to search')"
                                    style="height:85px;"
                                    no-resize
                            />
                            <b-input-group-prepend
                                    is-text
                                    class="ll-searchBtn"
                                    :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
                                    :disabled="loadingList"
                                    @click="searchById">
                                <feather-icon
                                        icon="SearchIcon"
                                        class="mr-25"
                                        size="18"
                                />
                            </b-input-group-prepend>
                        </b-input-group>
                    </b-card-body>
                </b-card>
            </div>
            <div class="flex-grow-1">
                <b-card class="card-browser-states ll-fHei" style="height:160px;">
                    <b-card-body>
                        <b-row class="ll-row">
                            <b-col lg="3">
                                <div class="ll-newbox">
                                    <b-avatar
                                            size="48"
                                            variant="light-secondary"
                                    >
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-package"></use>
                                        </svg>
                                    </b-avatar>
                                    <div class="ll-tit">{{ orderStatusCode.rec !== undefined ? orderStatusCode.rec : 0 }}</div>
                                    <div class="ll-des">{{ $t('Order Received') }}</div>
                                </div>
                            </b-col>
                            <b-col lg="3">
                                <div class="ll-newbox">
                                    <b-avatar
                                            size="48"
                                            variant="light-warning"
                                    >
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-rukuguanli"></use>
                                        </svg>
                                    </b-avatar>
                                    <div class="ll-tit">{{ orderStatusCode.prod !== undefined ? orderStatusCode.prod : 0 }}</div>
                                    <div class="ll-des">{{ $t('On Warehouse') }}</div>
                                </div>
                            </b-col>
                            <b-col lg="3">
                                <div class="ll-newbox">
                                    <b-avatar
                                            size="48"
                                            variant="light-danger"
                                    >
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-peisongguanli"></use>
                                        </svg>
                                    </b-avatar>
                                    <div class="ll-tit">{{ orderStatusCode.dip !== undefined ? orderStatusCode.dip : 0 }}</div>
                                    <div class="ll-des">{{ $t('Delivery In Progress') }}</div>
                                </div>
                            </b-col>
                            <b-col lg="3">
                                <div class="ll-newbox">
                                    <b-avatar
                                            size="48"
                                            variant="light-info"
                                    >
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-icon-test1"></use>
                                        </svg>
                                    </b-avatar>
                                    <div class="ll-tit">{{ orderStatusCode.comp !== undefined ? orderStatusCode.comp : 0 }}</div>
                                    <div class="ll-des">{{ $t('Completed') }}</div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </div>
        </div>

        <!-- second floor -->
        <div class="d-flex">
            <div class="mr-1">
                <b-overlay :show="loadingList" rounded="sm">
                    <div class="ll-cardHei ll-sHei ll-width">
                        <template v-if="rows.length > 0">
                            <div class="ll-tBox" v-show="isTbox">
                                <b-form-checkbox
                                        v-model="isAllCheck"
                                        class="ml-0 mt-50 mb-1"
                                        inline
                                        @input="selectedAll"
                                        v-if="check1"
                                >
                                    <span class="font-weight-bolder">{{$t('Check All')}}</span>
                                </b-form-checkbox>
                                <b-form-checkbox
                                        v-model="isAllCheck1"
                                        class="ml-0 mt-50 mb-1"
                                        inline
                                        @input="selectedAll1"
                                        v-if="check2"
                                >
                                    <span class="font-weight-bolder">{{$t('Check All')}}</span>
                                </b-form-checkbox>
                                <b-dropdown
                                        :text="$t('Batch Operation')"
                                        variant="info"
                                        class="ll-batch"
                                >
                                    <b-dropdown-item @click="statusReport">{{$t('Status')}} {{$t('Report')}}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="ll-cbox" id="cbox" ref="cbox">
                                <b-card
                                        v-for="(item, index) in rows"
                                        :key="index"
                                        class="card-browser-states ll-card ll-cardList"
                                        :class="item.tkID == selected ? 'bg-light' : 'bg-white'"
                                        @click="onDetail(item)"
                                        v-permission="[134]"
                                >
                                    <b-card-header class="p-1">
                                        <span class="font-weight-bold">{{ item.tkID }}</span>
                                        <div>
                                            <b-badge
                                                    pill
                                                    variant="light-info"
                                                    v-if="item.latestOrderStatus === 'COMP'"
                                            >
                                                <span>Completed</span>
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    variant="light-secondary"
                                                    v-if="item.latestOrderStatus === 'REC'"
                                            >
                                                <span>Order received</span>
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    variant="light-warning"
                                                    v-if="item.latestOrderStatus === 'PROD'"
                                            >
                                                <span>On Warehouse</span>
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    variant="light-danger"
                                                    v-if="item.latestOrderStatus === 'DIP'"
                                            >
                                                <span>Delivery in progress</span>
                                            </b-badge>
                                        </div>
                                    </b-card-header>
                                    <b-card-body class="d-flex justify-content-between align-items-center px-1 pt-0 pb-1">
                                        <b-form-checkbox
                                                v-model="item.choose"
                                                class="mr-0 mt-50"
                                                inline
                                                @input="itemCheck(item)"
                                        />
                                        <span>Created at {{ item.pkShippingDate }}</span>
                                        <div v-for="(t, i) of item.tag" :key="i" class="ll-0">
                                            <b-badge
                                                    pill
                                                    v-if="t === 'CUS_COMPLETE' || t === 'DELIVERED' || t === 'SELF_PICKUP'"
                                                    :variant="`light-${color}`">
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    v-if="t === 'NO_SORT_CODE' || t === 'RESCHEDULED' || t === 'DELIVERY_DELAY' || t === 'LATE_ARRIVAL'"
                                                    :variant="`light-${color1}`">
                                                <span v-if="t !== null">{{ t.replace(/_/g, ' ') }}</span>
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    v-if="t === 'REJECTED' || t === 'DAMAGED' || t === 'UNDELIVERABLE' || t === 'LOST' || t === 'DAMAGED' || t === 'SCRAPPED'"
                                                    :variant="`light-${color2}`">
                                                <span v-if="t !== null">{{ t.replace('_', ' ') }}</span>
                                            </b-badge>
                                            <b-badge
                                                    pill
                                                    v-if="t === 'REMOTE_AREA' || t === 'RETURNED'"
                                                    :variant="`light-${color3}`">
                                                <span v-if="t !== null">{{ t.replace('_', ' ') }}</span>
                                            </b-badge>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </template>
                        <div v-else class="ll-sHei d-flex align-items-center justify-content-center">
                            <h2>{{ $t('No order found') }}</h2>
                        </div>
                    </div>
                    <!-- 分页 -->
                    <div class="d-flex justify-content-between flex-wrap" style="height:80px;">
                        <div class="d-flex flex-column align-items-center mb-0 mt-1">
                            <div class="d-flex">
                                <span class="text-nowrap" style="margin:auto">Showing </span>
                                <b-form-select
                                        v-model="size"
                                        :options="pageOptions"
                                        class="mx-1"
                                        @input="handlePageChange"
                                />
                                <span class="text-nowrap" style="margin:auto">entries per page, total {{ totalSize }} entries</span>
                            </div>
                            <div class="mt-1">
                                <b-pagination
                                        v-model="page"
                                        :total-rows="totalSize"
                                        :per-page="size"
                                        first-number
                                        last-number
                                        class="mb-0 mt-1 mt-sm-0"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        @change="handleChangePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
            <div class="flex-grow-1">
                <b-card class="card-browser-states ll-rightCard">
                    <b-card-body>
                        <b-tabs
                                pills
                                class="ll-tabs"
                                v-if="selectedItem"
                        >
                            <!-- Tab: Overview -->
                            <b-tab active>
                                <template #title>
                                    <span class="d-none d-sm-inline">{{ $t('Overview') }}</span>
                                </template>
                                <!-- <job-tab-1 :orderItem="selectedItem" :heiVal="height"></job-tab-1> -->
                                <job-tab-1 :orderItem="selectedItem"
                                           :jobDetails1="jobDetails1"
                                           :jobDetails2="jobDetails2"
                                           :jobDetails3="jobDetails3"
                                ></job-tab-1>
                            </b-tab>

                            <!-- Tab: Item -->
                            <b-tab>
                                <template #title>
                                    <span class="d-none d-sm-inline">{{ $t('Item') }}</span>
                                </template>
                                <job-tab-2 :heiVal="height"
                                           :jobDetails1="jobDetails1"
                                           :jobDetails4="jobDetails4"
                                ></job-tab-2>
                            </b-tab>

                            <!-- Tab: Operation -->
                            <b-tab>
                                <template #title>
                                    <span class="d-none d-sm-inline" v-permission="[200]">{{ $t('Operation') }}</span>
                                </template>
                                <job-tab-3 :heiVal="height"
                                           :jobDetails1="jobDetails1"
                                           :jobDetails2="jobDetails2"
                                           :jobDetails3="jobDetails3"
                                           :jobDetails5="jobDetails5"
                                           :jobDetails6="jobDetails6"
                                ></job-tab-3>
                            </b-tab>
                        </b-tabs>
                        <div class="display-4 ll-sHei d-flex align-items-center justify-content-center" v-else>
                            {{ $t('Please select an order to view') }}
                        </div>
                    </b-card-body>
                </b-card>
            </div>
        </div>

        <!-- Filters sidebar -->
        <b-sidebar id="filterS" title=""
                   right
                   shadow
                   bg-variant="white"
                   backdrop>
            <div class="px-3">
                <app-collapse class="ll-collapse">
                    <app-collapse-item :title="$t('Status')">
                        <v-select
                                id="status"
                                v-model="latestOrderStatus1"
                                multiple
                                :options="statusList"
                                label="name"
                                @input="selStatus"
                                placeholder="Please Select Status"
                        ></v-select>
                    </app-collapse-item>
                    <app-collapse-item :title="$t('Tags')">
                        <v-select
                                id="tags"
                                v-model="tagss"
                                multiple
                                :options="tagsList"
                                label="codeDescription"
                                @input="selTag"
                                placeholder="Please Select Tags"
                        ></v-select>
                    </app-collapse-item>
                    <app-collapse-item :title="$t('Service Party')">
                        <v-select
                                id="serviceParty"
                                v-model="serviceParty"
                                multiple
                                :options="sPartyList"
                                @input="selSParty"
                                label="codeDescription"
                                placeholder="Please Select Service Party"
                        ></v-select>
                    </app-collapse-item>
                </app-collapse>
                <div class="ll-btn">
                    <b-button
                            variant="secondary"
                            class="mr-1"
                            @click="onClear"
                    >
                        {{ $t('Clear') }}
                    </b-button>
                    <b-button variant="info"
                              v-b-toggle.filterS
                              @click="searchById">
                        {{ $t('Search') }}
                    </b-button>
                </div>
            </div>
        </b-sidebar>
    </section>
</template>

<script>
    import {
        BBreadcrumb, BBreadcrumbItem, BFormTextarea,
        BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
        BButton, BSidebar, BInputGroupPrepend, BInputGroup,
        BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
        BPagination, BFormSelect, BAvatar, BOverlay,BFormCheckbox,BDropdown,BDropdownItem,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import '@core/scss/vue/libs/vue-flatpicker.scss'
    import vSelect from 'vue-select'
    import jobTab1 from './components/jobTab1.vue'
    import jobTab2 from './components/jobTab2.vue'
    import jobTab3 from './components/jobTab3.vue'
    import { debounce } from "@/libs/fun.js"
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import permission from '@core/directives/permission/index.js'

    export default {
        components: {
            BFormCheckbox,
            BDropdown,
            BDropdownItem,
            jobTab1,
            jobTab2,
            jobTab3,
            BBreadcrumb,
            BBreadcrumbItem,
            BRow,
            BCol,
            BCard,
            BFormGroup,
            BFormInput,
            BCardHeader,
            BCardTitle,
            BCardBody,
            BBadge,
            BButton,
            BTable,
            flatPickr,
            BSidebar,
            vSelect,
            BInputGroupPrepend,
            BInputGroup,
            vSelect,
            BFormTextarea,
            BTab,
            BTabs,
            BPagination,
            BFormSelect,
            BAvatar,
            AppCollapse,
            AppCollapseItem,
            // eslint-disable-next-line vue/no-unused-components
            ToastificationContent,
            BOverlay,
        },
        directives: {
            'b-toggle': VBToggle,
            Ripple,
            permission,
        },
        data() {
            return {
                // statusList: ['REC', 'PROD', 'DIP', 'COMP', 'TRA'],
                statusList: [
                    { name: 'Order received', code: 'REC' },
                    { name: 'In transit', code: 'TRA' },
                    { name: 'On Warehouse', code: 'PROD' },
                    { name: 'Delivery in progress', code: 'DIP' },
                    { name: 'Completed', code: 'COMP' },
                ],
                trackingIDs: '',
                dateConfig: {
                    // dateFormat: 'Y-m-d H:i:ss',
                    mode: 'range',
                    defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
                    // enableTime: true, // 启用时间选择器
                },
                condition: {
                    page: '1',
                    size: '100',
                    shipmentDateFrom: null,
                    shipmentDateTo: null,
                    serviceParty: null,
                    orderBy: 'latestOrderStatusTime',
                    sequence: 'desc', //desc降序 asc升序
                    tags: null,
                    latestOrderStatus: null,
                    trackingIDs: null,
                    // orgId: 'org001',
                    isAmazon: true,
                },
                time: '',
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageOptions: [50, 100, 200],
                page: null,
                size: 100,
                totalSize: null,
                totalPage: null,
                rows: [],
                orderStatus: '',
                orderStatusCode: {
                    rec: 0, // Order received
                    prod: 0, // On Warehouse
                    dip: 0, // Delivery in progress
                    comp: 0, // Completed
                },
                tkid: '',
                orderId: '',
                selected: '',
                selectedItem: null,
                color: 'info',
                color1: 'primary',
                color2: 'danger',
                color3: 'warning',
                tagsList: [],
                sPartyList: [],
                serviceParty: '',
                windowWidth: document.documentElement.clientWidth,  //屏幕宽度
                windowHeight: document.documentElement.clientHeight,   //屏幕高度
                height: document.documentElement.clientHeight - 360,
                hei: (document.documentElement.clientHeight - 360 - 46) / 10,
                tagss: null,
                filterBg: {
                    '--background': '#fff'
                },
                latestOrderStatus1: '',
                loadingList: false,
                jobDetails1: null,
                jobDetails2: null,
                jobDetails3: null,
                jobDetails4: null,
                jobDetails5: null,
                jobDetails6: null,
                isTbox: true,
                isAllCheck: false,
                isAllCheck1: false,
                check1: true,
                check2: false,
                tkIds: [],
                all:false
            }
        },
        computed: {
        },
        watch: {
            windowHeight(val) {
                // console.log(val, this.windowHeight)
                this.height = val - 360
                this.hei = (this.height - 46) / 10
            },
            windowWidth(val) {
                // console.log('width', val)
            },
            'latestOrderStatus1'(val) {
                this.setBg(val)
            },
            'condition.tags'(val) {
                this.setBg(val)
            },
            'condition.serviceParty'(val) {
                this.setBg(val)
            },
            rows(val) {
                if (val) {
                    this.scrollFunction()
                }
            }
        },
        created() {},
        mounted() {
            this.getList()
            this.configTags()
            this.querySparty()
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    window.fullWidth = document.documentElement.clientWidth
                    this.windowHeight = window.fullHeight
                    this.windowWidth = window.fullWidth
                })()
            }
        },
        methods: {
            scrollFunction () {
                // this.domObj = document.getElementById('cbox')
                this.domObj = this.$refs.cbox
                if (this.domObj) {
                    if (this.domObj.attachEvent) { // IE
                        this.domObj.attachEvent('onmousewheel', this.mouseScroll)
                    } else if (this.domObj.addEventListener) {
                        this.domObj.addEventListener('DOMMouseScroll', this.mouseScroll, false)
                    }
                    this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll
                }
            },
            mouseScroll(event) { // google 浏览器下
                // 向上滚 event.wheelDelta = 120
                // 向下滚 event.wheelDelta = -120
                if (event.wheelDelta > 0){
                    this.isTbox = true
                } else {
                    this.isTbox = false
                }
            },
            // filter图标背景颜色设置
            setBg(val) {
                if (val !== null) {
                    this.filterBg= {
                        '--background': '#ff9f43'
                    }
                } else {
                    this.filterBg= {
                        '--background': '#fff'
                    }
                }
            },
            // 默认日期格式转换
            dateFormats() {
                const fromDate = new Date()
                // let datetime = fromDate.getFullYear() + "-" +
                // (fromDate.getMonth()> 9 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
                // + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
                // + ' ' + (fromDate.getHours()>9 ? (fromDate.getHours()) : '0'+(fromDate.getHours())) + ':'
                // + (fromDate.getMinutes()>9 ? (fromDate.getMinutes()) : '0'+(fromDate.getMinutes()))
                // + ':' + (fromDate.getSeconds()>9 ? (fromDate.getSeconds()) : '0'+(fromDate.getSeconds()))
                let datetime = fromDate.getFullYear() + "-" +
                    (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
                    + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
                    + ' ' + '23:59:59'

                const toDate = new Date((new Date).setDate((new Date).getDate() - 3))
                // let datetime1 = toDate.getFullYear() + "-" +
                // (toDate.getMonth()> 9 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
                // + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
                //  + ' ' + (toDate.getHours()>9 ? (toDate.getHours()) : '0'+(toDate.getHours())) + ':'
                // +(toDate.getMinutes()>9 ? (toDate.getMinutes()) : '0'+(toDate.getMinutes())) + ':'
                // + ':' + (toDate.getSeconds()>9 ? (toDate.getSeconds()) : '0'+(toDate.getSeconds()))
                let datetime1 = toDate.getFullYear() + "-" +
                    (toDate.getMonth()> 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
                    + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
                    + ' ' + '00:00:00'

                this.condition.shipmentDateFrom = datetime1
                this.condition.shipmentDateTo = datetime
            },
            // 查询列表search
            queryList() {
                this.loadingList = true
                this.$http.post('/jobkes/searchAmz', this.condition).then(res => {
                    // console.log(res.data.data)
                    const info = res.data.data
                    this.rows = info.content
                    this.page = info.page
                    this.size = info.size
                    this.totalSize = info.totalSize
                    this.totalPage = info.totalPage
                    const arr = []
                    const obj = {}
                    this.rows.map(i => {
                        if (i.latestOrderStatus !== null) {
                            arr.push(i.latestOrderStatus.toLowerCase())
                        }
                    })
                    this.getStatusCount()
                    this.loadingList = false
                }).catch(error => console.log(error))
            },
            // 首次查询
            getList() {
                this.dateConfig.dateFormat = 'Y-m-d'
                this.dateFormats()
                this.queryList()
            },
            // 根据日期查询
            onChange: debounce(function() {
                // this.dateConfig.dateFormat = 'Y-m-d H:i:ss'
                const dd = this.time.split('to ')
                if (dd[0] !== '') {
                    this.condition.shipmentDateFrom = (dd[1] !== undefined)? (dd[0] + '00:00:00') : (dd[0] + ' 00:00:00')
                    this.condition.shipmentDateTo = ((dd[1] !== undefined)? dd[1] : dd[0])  + ' 23:59:59'
                } else {
                    this.condition.shipmentDateFrom = null
                    this.condition.shipmentDateTo = null
                }
                this.queryList()
            }, 1000),
            // 根据ID查询
            searchById() {
                this.selected = ''
                if (this.trackingIDs.trim() !== ''){
                    this.condition.shipmentDateFrom = null
                    this.condition.shipmentDateTo = null
                    this.condition.page = '1'
                    this.condition.trackingIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
                } else {
                    if (this.time !== '') {
                        this.onChange()
                    } else {
                        this.dateFormats()
                    }
                    this.condition.trackingIDs = null
                }
                this.queryList()
            },
            handleChangePage(page) {
                this.condition.page = page
                this.queryList()
            },
            handlePageChange(active) {
                this.condition.size = active
                this.queryList()
            },
            onClear() {
                this.latestOrderStatus1 = null
                this.condition.tags = null
                this.tagss = null
                this.condition.serviceParty = null
            },
            // 点击查看详情
            onDetail(obj) {
                if (obj) {
                    this.selected = obj.tkID
                    // this.selectedItem = obj
                    this.getjobDetails(obj.orderID)
                    this.searchJobkes(obj.tkID)
                    this.jobTrack(obj.tkID)
                    this.getPackageProducts(obj.orderID)
                    this.jobSla(obj.tkID)
                    this.jobCharge(obj.tkID)
                } else {
                    this.tkid = ""
                    this.orderId = ""
                    this.selected = ""
                    this.selectedItem = null
                }
            },
            getjobDetails(oId) {
                this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
                    this.jobDetails1 = res.data.data
                })
            },
            searchJobkes(tkid){
                const para = {}
                para.page = '1'
                para.size = '10'
                para.isAmazon = true
                para.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
                this.$http.post('/jobkes/search', para).then(res => {
                    if (res.data.data.content.length !== 0){
                        this.jobDetails2 = res.data.data.content[0]
                        this.selectedItem = res.data.data.content[0]
                    }
                })
            },
            jobTrack(tkid){
                const para = {}
                para.page = '1'
                para.size = '1000'
                para.startEventTime = null
                para.endEventTime = null
                para.statusCode = null
                para.isDisplay = null
                para.orderBy = 'id'
                para.orderMole = '0'
                para.isAmazon = true
                para.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
                this.$http.post('/jobTrack/search', para).then(res => {
                    this.jobDetails3 = res.data.data.content
                })
            },
            getPackageProducts(oId){
                this.$http.get('/jobkes/getPackageProducts', { params: { orderId: oId } }).then(res => {
                    this.jobDetails4 = res.data.data
                })
            },
            jobSla(tkid){
                const data = {}
                data.tkId = tkid
                this.$http.post('jobSla/search', data).then(res =>{
                    this.jobDetails5 = res.data.data
                })
            },
            jobCharge(tkid){
                const para = {}
                para.page = '1'
                para.size = '10'
                para.invoiceType = null
                para.beginDate = null
                para.endDate = null
                para.invoiceNo = null
                para.orderBy = 'id'
                para.status = null
                para.refNo = tkid
                this.$http.post('invoiceDetail/search', para).then(res => {
                    this.jobDetails6 = res.data.data.content
                })
            },
            // query tags
            configTags() {
                // this.$http.get('/configTags/findALLConfigTagsByisEnabled').then(res => {
                //   this.tagsList = res.data.data
                // })
                this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'tags'}}).then(res => {
                    this.tagsList = res.data.data
                })
            },
            selTag(t) {
                const arr = []
                t.map(item => {
                    arr.push(item.codeName)
                })
                this.condition.tags = arr
            },
            selStatus(t) {
                const arr = []
                t.map(item => {
                    arr.push(item.code)
                })
                this.condition.latestOrderStatus = arr
                // console.log(t)
                // this.condition.latestOrderStatus = t.code
            },
            // query service party
            querySparty() {
                // this.$http.get('jobkes/getServicePartyList').then(res => {
                //   // console.log(res.data.data)
                //   this.sPartyList = res.data.data
                // })
                this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'serviceParty'}}).then(res => {
                    this.sPartyList = res.data.data
                })
            },
            selSParty(d) {
                const arr = []
                d.map(item => {
                    arr.push(item.codeName)
                })
                this.condition.serviceParty = arr
                // this.condition.serviceParty = p.serviceParty
                // this.condition.serviceParty = d ? d.codeName : null
                // this.serviceParty = d ? d.codeDescription : null
            },
            // 查询status次数
            getStatusCount() {
                this.$http.post('jobkes/getTotalStatusList', this.condition).then(res => {
                    const result = res.data.data
                    this.orderStatusCode.comp = (result.COMP) !== 'undefined' ? result.COMP : 0
                    this.orderStatusCode.rec = (result.REC) !== 'undefined' ? result.REC : 0
                    this.orderStatusCode.dip = (result.DIP) !== 'undefined' ? result.DIP : 0
                    this.orderStatusCode.prod = (result.PROD) !== 'undefined' ? result.PROD : 0
                    // result.map(i => {
                    //   switch (i.latestOrderStatus) {
                    //     case 'COMP':
                    //       this.orderStatusCode.comp = i.sum
                    //       break
                    //     case 'REC':
                    //       this.orderStatusCode.rec = i.sum
                    //       break
                    //     case 'PROD':
                    //       this.orderStatusCode.prod = i.sum
                    //       break
                    //     case 'DIP':
                    //       this.orderStatusCode.dip = i.sum
                    //       break
                    //     default:
                    //       break
                    //   }
                    // })
                })
            },
            // 全选 全不选
            selectedAll() {
                if (this.isAllCheck == true) {
                    if (this.trackingIDs) {
                        this.all = false
                    }else{
                        this.all = true
                    }
                }else{
                    this.all = false
                }
                this.rows.map(i=>{
                    !this.isAllCheck? (i.choose=false) : (i.choose=true)
                })

            },
            selectedAll1() {
                if (this.isAllCheck1) {
                    this.check1 = true
                    this.check2 = false
                }
            },
            itemCheck(item){
                let arrTrue=[]
                let arrFalse=[]
                this.rows.map(i=>{
                    if (i.choose) {
                        arrTrue.push(i.choose)
                    } else {
                        arrFalse.push(i.choose)
                    }
                })
                if (arrTrue.length === this.rows.length) {
                    this.isAllCheck = true
                    this.check1 = true
                    this.check2 = false
                }
                if (arrFalse.length >0) {
                    // this.isAllCheck = false
                    this.check1 = false
                    this.check2 = true
                }
                if (arrFalse.length === this.rows.length) {
                    this.isAllCheck = false
                    this.check1 = true
                    this.check2 = false
                }
                this.tkIds.push(item.tkID)
            },
            statusReport() {
                if (this.time !== '') {
                    this.onChange()
                } else {
                    this.dateFormats()
                }
                if (!this.condition.shipmentDateFrom || !this.condition.shipmentDateTo) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Date can not be empty',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                    return
                }
                let obj = {}
                obj.from = this.condition.shipmentDateFrom
                obj.to = this.condition.shipmentDateTo
                obj.jobKesByPage = this.condition
                obj.reportType = 'StatusCode'
                obj.orderStatus = null
                // obj.orgIds = ["org001"]
                obj.isAmazon = true
                obj.tkIds = this.tkIds
                obj.all = this.all
                console.log(obj)
                this.$http.post('/exportReport/publish', obj).then(res=> {
                    if (res.data.code == '200') {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.$router.push({ name: 'batch-update' })
                    }
                })
            },
        },
    }
</script>
<style scoped>
    .ll-batch{
        margin-left: 1rem;
        padding: 0.5rem 0;
    }
    .ll-tBox{
        height: 46px;
        z-index: 9;
        background-color: #fff;
        padding-left: 0.5rem;
        text-align: left;
    }
    .ll-width{
        width: 400px;
    }
    .ll-cardHei1>.card-body{
        padding: 0;
    }
    .ll-sHei{
        height: 62vh;
    }
    @media (min-width :2000px){
        .ll-sHei {
            height: 68vh;
        }
    }
    @media (min-width :1800px) and (max-width: 2000px){
        .ll-sHei {
            height: 66vh;
        }
    }
    @media (max-width: 1800px){
        .ll-sHei {
            height: 70vh;
        }
    }
    .ll-badge{
        /* padding: 2px; */
        margin-right: 2px;
    }
    .ll-searchBtn div{
        background-color: #ff9f43;
        color: #fff;
        width: 60px;
        display: flex;
        justify-content: center;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }
    .ll-fHei{
        /* height: 124px; */
        height: var(--height);
        margin-bottom: 10px;
    }
    .icon{
        width: 3em;
        height: 3em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
    .ll-conBox{
        display: flex;
        align-items: center;
    }
    .ll-box{
        margin-left: 10px;
    }
    .ll-box span{
        display: block;
        text-align: center;
    }
    .ll-newbox{
        text-align: center;
    }
    .ll-newbox .ll-tit{
        font-size: 16px;
        font-weight: bold;
    }
    .ll-searchBox{
        margin-top: 10px;
    }
    .ll-fHei>.card-body{
        display: flex;
        align-items: center;
    }
    .ll-cardBody{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ll-card>.card-body{
        padding: 0;
        /* height: 70px; */
        height: var(--cardbody-height);
    }
    .ll-tabs .nav-pills .nav-link.active{
        background-color: unset;
        color: #5e5873;
        box-shadow: none;
        border: none;
        border-bottom: 3px solid #ed6d01;
        border-radius: 0px;
    }
    .ll-tabs .nav-item{
        font-weight: bold;
    }
    .ll-btn{
        position: fixed;
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        padding-right: 6rem;
        width: 100%;
    }
    .ll-tkid{
        color: #161d31;
    }
    .ll-cardList{
        margin-bottom: 5px;
        cursor: pointer;
    }
    .ll-cardList .card-header{
        padding: 0.5rem;
    }
    .ll-cardList .ll-cardBody{
        padding: 0.5rem;
    }
    .ll-cardHei{
        overflow-y: auto;
    }
    .ll-cardHei1>.card-body{
        padding: 0;
    }
    .ll-page{
        position: absolute;
        bottom: -10px;
    }
    .ll-cardActive{
        background: #f6f6f6;
    }
    .ll-backg{
        background: #f6f6f6;
    }
    .searchInput{
        height: var(--height1);
    }
    .ll-0+ div{
        display: none;
    }
    .ll-outline> div{
        background: var(--background);
    }
</style>
<style>
    .ll-tabs .nav-item{
        font-weight: bold;
    }
    .ll-collapse .card{
        box-shadow: none;
    }
    .ll-text{
        margin: auto;
    }
    .ll-row{
        flex-wrap: nowrap;
    }
</style>
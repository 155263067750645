<template>
  <b-overlay :show="loading" rounded="sm" v-if="orderItem">
    <section>
      <b-row class="bg-white">
        <b-col lg="4" class="ll-spanBox">
          <span>{{ orderItem.tkID }}</span>
        </b-col>
        <b-col lg="4" class="ll-spanBox">
          <span>{{ latestOrderStatus }}</span>
          <span v-if="showStatusTime" class="ll-sumt">on {{ latestOrderStatusTime }}</span>
          <b-badge
            pill
            class="ll-badge"
            :variant="tags === 'DELIVERED' ? 'light-info' :'light-danger'"
            v-if="tags !== null"
          >
            {{tags.toLowerCase().replace(/[_,]/g, ' ')}}
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="ll-hr bg-white">
        <b-col lg="3" class="ll-Sedfloor ll-line">
          <p class="ll-pTit">{{ $t('General') }}</p>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Customer order no') }}:</label>
            <span class="ll-span">{{ orderItem.customerOrderNo }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Shipment date') }}:</label>
            <span class="ll-span"> {{ orderItem.pkShippingDate }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Est. delivery date') }}:</label>
            <span class="ll-span">{{eadDate}}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Manifest no') }}:</label>
            <span class="ll-span">{{ orderItem.mfNo}}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Package qty') }}:</label>
            <span class="ll-span">{{ pkSn }}{{ orderQty }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Item qty') }}:</label>
            <span class="ll-span">{{itemQty}}</span>
          </div>
        </b-col>
        <b-col lg="3" class="ll-Sedfloor ll-line">
          <p class="ll-pTit">{{ $t('Delivery worksheet') }}</p>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('KEC order id') }}:</label>
            <span class="ll-span">{{ orderItem.orderID }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('MAWB No') }}:</label>
            <span class="ll-span">{{mawbNo}}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Delivery attempts') }}:</label>
            <span class="ll-span">{{ orderItem.deliveryAttempts }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Number of returns') }}:</label>
            <span class="ll-span">{{ orderItem.numberOfReturns }}</span>
          </div>
          <div class="ll-box">
            <label class="ll-lab">{{ $t('Last mile label') }}:</label>
            <b-link :href="labelUrl" target="_blank">
              <svg class="icon ll-imgs" aria-hidden="true">
                <use xlink:href="#icon-Waybill"></use>
              </svg>
            </b-link>
          </div>
        </b-col>
        <b-col lg="6" class="ll-Sedfloor">
          <b-row>
            <b-col lg="6">
              <p class="ll-pTit">{{ $t('Ship from') }}</p>
              <div class="ll-p1">
                <p>{{ fromAddress }} {{info.fromDistinct}}</p>
                <p>{{ fromCity }} {{fromState}} {{info.fromZip}}</p>
                <p>{{ info.fromCountry }} {{ fromCountryCode }}</p>
                <p>{{ info.fromName }}</p>
              </div>
            </b-col>
            <b-col lg="6">
              <p class="ll-pTit">{{ $t('Ship to') }}</p>
              <div class="ll-p1">
                <p>{{ caddress }} {{ info.cdistinct }}</p>
                <p>{{ ccity }} {{info.cstate}} {{info.czip}}</p>
                <p>{{ info.ccountry }} {{ ccountryCode }}</p>
                <p>{{ info.cname }}</p>
                <p>{{ info.cphone }}</p>
                <p>{{ info.cemail }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="ll-padding">
          <b-card
            no-body
            class="card-browser-states ll-c ll-hei"
          >
            <b-card-header>
              <b-card-title class="ll-title">{{ $t('Shipment status tracking') }}</b-card-title>
              <b-button
                variant="flat-secondary"
                @click="getStatus"
                v-if="orderItem != null && orderItem.tkID !== ''"
              >
                <feather-icon icon="RefreshCcwIcon" />
              </b-button>
              <b-form-input
                v-model="searchPID"
                :placeholder="$t('Search')"
                type="search"
                class="ll-input"
              />
            </b-card-header>
            <b-card-body class="ll-cb">
              <b-table :items="items" :fields="fields"
                :borderless='false' :hover="true"
                :sticky-header="true"
                :filter="searchPID"
                class="ll-tableHei"
              >
                <template #cell(externalStatusCode)="scope">
                  <span>{{scope.value}} {{(scope.value !== null && scope.item.externalStatusCode2 !== null) ? ' /' : ''}} {{scope.item.externalStatusCode2}}</span>
                </template>
                <template #cell(statusType)="scope">
                  <!-- <feather-icon
                    :icon="(scope.value==='NORMAL') ? icon1 : icon2"
                    size="16"
                    :class="(scope.value==='NORMAL') ? color1 : color2"
                  /> -->
                  <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='NORMAL'">
                    <use xlink:href="#icon-form_icon_normal"></use>
                  </svg>
                  <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='EXCEPTION'">
                    <use xlink:href="#icon-form_icon_disable"></use>
                  </svg>
                  <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='MESSAGE'">
                    <use xlink:href="#icon-icon-test"></use>
                  </svg>
                </template>
                <template #cell(statusDesc)="scope">
                  <span>
                    {{scope.value}} {{scope.item.originStatusDesc!== null ? '('+scope.item.originStatusDesc+')' : null}}
                  </span>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BTable,
  BFormInput, BLink, BButton, BBadge, BOverlay
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BFormInput,
    BLink,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BBadge,
    BOverlay,
  },
  props: {
    orderItem: Object,
    heiVal: Number,
    jobDetails1: Object,
    jobDetails2: Object,
    jobDetails3: Array,
  },
  data() {
    return {
      condition: {
        page: '1',
        size: '10',
        trackingIDs: null,
        orderBy: 'id',
        orderMole: 1,
        isAmazon: true,
      },
      latestOrderStatus: '',
      jobTrack: {
        page: '1',
        size: '1000',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
        isAmazon: true,
      },
      status: '',
      customerOrderNumber: '',
      packageQty: '',
      fromAddress: '',
      fromCity: '',
      fromCountry: '',
      fromName: '',
      fromState: '',
      fromZip: '',
      toAddress: null,
      toCity: null,
      toCountry: null,
      toName: null,
      toState: null,
      toZip: null,
      items: [],
      fields: [
        { key: 'statusType', label: '', thStyle: { width:'40px'} },
        { key: 'eventTime', label: this.$t('Time Updated'), thStyle: { width:'140px'} },
        { key: 'statusCode', label: this.$t('Status Code'), thStyle: { width:'300px'} },
        { key: 'statusDesc', label: this.$t('Description') },
        { key: 'amazonStatusCode', label: this.$t('Amazon Status') },
        { key: 'isClockstops', label: this.$t('Is Clock Stop') },
        { key: 'externalStatusCode', label: this.$t('External Code') },
        { key: 'updateSource', label: this.$t('Source') },
        { key: 'operator', label: this.$t('Operator') },
        { key: 'isSentAmazon', label: this.$t('Is Sent Amazon') },
      ],
      icon1: 'CheckCircleIcon',
      icon2: 'AlertTriangleIcon',
      color1: 'icon-sussess',
      color2: 'icon-fail',
      showStatusTime: false,
      itemQty: '',
      mawbNo: '',
      searchPID: '',
      cdistinct: '',
      cphone: '',
      eadDate: '',
      info: {},
      caddress: null,
      ccity: null,
      ccountryCode: null,
      fromCountryCode: null,
      cstate: null,
      orderQty: null,
      pkSn: null,
      labelUrl: null,
      loading: false,
      tags: '',
      tkid: null,
    }
  },
  computed: {
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
    },
    jobDetails2(val){
      this.getinfo2(val)
    },
    jobDetails3(val){
      this.getinfo3(val)
    },
    // orderItem(newVal) {
    //   if (newVal) {
    //     this.statusTrack(newVal.tkID)
    //     this.getDetail1(newVal.orderID)

    //     this.labelUrl = `${axios.defaults.baseURL}/label/downLoadFile/${newVal.tkID}`
    //     switch (newVal.latestOrderStatus) {
    //       case 'COMP':
    //         this.latestOrderStatus = 'Completed'
    //         this.showStatusTime = true
    //         break
    //       case 'REC':
    //         this.latestOrderStatus = 'Order received'
    //         this.showStatusTime = false
    //         break
    //       case 'PROD':
    //         this.latestOrderStatus = 'On Warehouse'
    //         this.showStatusTime = false
    //         break
    //       case 'DIP':
    //         this.latestOrderStatus = 'Delivery in progress'
    //         this.showStatusTime = false
    //         break
    //       default:
    //         // this.latestOrderStatus = 'In transit'
    //         // this.showStatusTime = false
    //         break
    //     }
    //   }
    // },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getinfo1(info) {
      this.info = info
      this.caddress = (info.caddress !== null && info.cdistinct !== null && info.caddress !== '' && info.cdistinct !== '') ? (info.caddress + ',') : info.caddress
      this.ccity = (info.ccity !== null && info.ccity !== '') ? (info.ccity + ',') : info.ccity
      this.ccountryCode = (info.ccountryCode !== null && info.ccountryCode !=='') ? ('(' + info.ccountryCode + ')') : info.ccountryCode
      this.fromAddress = (info.fromAddress !== null && info.fromAddress !=='') ? (info.fromAddress + ',') : info.fromAddress
      this.fromCity = (info.fromCity !== null && info.fromCity !== '') ? (info.fromCity + ',') : info.fromCity
      this.fromCountryCode = (info.fromCountryCode !== null && info.fromCountryCode !== '') ? ('(' + info.fromCountryCode + ')') : info.fromCountryCode
      this.fromState = (info.fromState !== null && info.fromState !=='') ? (info.fromState + ',') : info.fromState
      this.cstate = (info.cstate !== null && info.cstate !== '') ? (info.cstate + ',') : info.cstate

      this.customerOrderNumber = info.customerOrderNumber
      this.packageQty = info.packages.length
      this.mawbNo = info.mawb
      this.itemQty = info.packages[0].itemqty
      this.eadDate = info.eadDate
      this.orderQty = info.orderQty
      this.pkSn = (info.packages[0].pkSn !== null) ? (info.packages[0].pkSn + '/') : info.packages[0].pkSn
    },
    getinfo2(info) {
      this.tags = info.tag[0]
      this.latestOrderStatusTime = info.latestOrderStatusTime
      this.labelUrl = `${axios.defaults.baseURL}/label/downLoadFile/${info.tkID}`
      this.tkid = info.tkID
      switch (info.latestOrderStatus) {
        case 'COMP':
          this.latestOrderStatus = 'Completed'
          this.showStatusTime = true
          break
        case 'REC':
          this.latestOrderStatus = 'Order received'
          this.showStatusTime = false
          break
        case 'PROD':
          this.latestOrderStatus = 'On Warehouse'
          this.showStatusTime = false
          break
        case 'DIP':
          this.latestOrderStatus = 'Delivery in progress'
          this.showStatusTime = false
          break
        default:
          // this.latestOrderStatus = 'In transit'
          // this.showStatusTime = false
          break
      }
    },
    getinfo3(info) {
      this.items = info
    },
    // 刷新状态
    getStatus() {
      if (this.tkid !== null){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        let tkids = []
        tkids.push(this.tkid)
        setTimeout(() => {
          this.$http.post('/debug/status/debugKesStatusOut',tkids).then(res => {
            console.log(res)
          })
        }, 3000)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    getDetail1(oId) {
      this.loading = true
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.info = info
          // console.log('res1', this.info)
          this.caddress = (info.caddress !== null && info.cdistinct !== null && info.caddress !== '' && info.cdistinct !== '') ? (info.caddress + ',') : info.caddress
          this.ccity = (info.ccity !== null && info.ccity !== '') ? (info.ccity + ',') : info.ccity
          this.ccountryCode = (info.ccountryCode !== null && info.ccountryCode !=='') ? ('(' + info.ccountryCode + ')') : info.ccountryCode
          this.fromAddress = (info.fromAddress !== null && info.fromAddress !=='') ? (info.fromAddress + ',') : info.fromAddress
          this.fromCity = (info.fromCity !== null && info.fromCity !== '') ? (info.fromCity + ',') : info.fromCity
          this.fromCountryCode = (info.fromCountryCode !== null && info.fromCountryCode !== '') ? ('(' + info.fromCountryCode + ')') : info.fromCountryCode
          this.fromState = (info.fromState !== null && info.fromState !=='') ? (info.fromState + ',') : info.fromState
          this.cstate = (info.cstate !== null && info.cstate !== '') ? (info.cstate + ',') : info.cstate

          this.customerOrderNumber = info.customerOrderNumber
          this.packageQty = info.packages.length
          // this.fromAddress = info.fromAddress
          // this.fromCity = info.fromCity
          // this.fromCountry = info.fromCountry
          // this.fromName = info.fromName
          // this.fromState = info.fromState
          // this.fromZip = info.fromZip
          // this.toAddress = info.caddress
          // this.toCity = info.ccity
          // this.toCountry = info.ccountry
          // this.toName = info.cname
          // this.toState = info.cemail
          // this.toZip = info.czip
          // this.cdistinct = info.cdistinct
          // this.cphone = info.cphone
          this.mawbNo = info.mawb
          this.itemQty = info.packages[0].itemqty
          this.eadDate = info.eadDate
          this.orderQty = info.orderQty
          this.pkSn = (info.packages[0].pkSn !== null) ? (info.packages[0].pkSn + '/') : info.packages[0].pkSn
        }
        this.loading = false
      }).catch(error => console.log(error))
    },
    // shipment status tracking
    statusTrack(tkid) {
      this.loading = true
      this.jobTrack.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', this.jobTrack).then(res => {
        const info = res.data.data.content
        this.items = info
        this.loading = false
      })
    },
  },
}
</script>
<style scoped>
.ll-badge{
  margin-left: 1rem;
  text-transform: capitalize;
  font-size: 16px !important;
}
.ll-spanBox{
  margin: 10px 0;
}
.ll-spanBox span{
  font-size: 18px;
  font-weight: bold;
}
.ll-box{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ll-box .ll-lab{
  color: #6e6b7b;
}
.ll-pTit{
  /* color: #4B7902; */
  color: #ed6d01;
  font-size: 14px;
}
.ll-line{
  border-right: 1px solid #d0d2d6;
}
/* .ll-p{
  font-weight: 500;
  color: #5e5873;
} */
.ll-p p{
  margin-bottom: 0.2857rem;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
}
.ll-Sedfloor{
  margin-bottom: 10px;
}
.ll-trackStatus{
  width: 80%;
  border: 1px solid #eee;
  height: 40px;
  display: flex;
}
.ll-colorSpan{
  display: inline-block;
  width: 2%;
  height: 39px;
  background: #4B7902;
}
.ll-desSpan{
  line-height: 40px;
  padding-left: 20px;
}
.ll-wrapper{
  display: flex;
}
.ll-wrap{
  width: 100%;
}
.ll-timeline{
  height: 58px;
}
.ll-tl{
 margin-bottom: 15px;
}
.ll-pt{
  margin: 20px 0;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-tableHei{
  height: 20vh;
  max-height: unset;
}
@media (min-width :2000px){
  .ll-tableHei {
    height: 36vh;
  }
}
@media (min-width :1800px) and (max-width: 2000px){
  .ll-tableHei {
    height: 30vh;
  }
}
@media (max-width: 1800px){
  .ll-tableHei {
    height: 16vh;
  }
}
.ll-padding{
  padding: 0;
}
.ll-cb{
  padding: 0;
}
.ll-c{
  margin-top: 10px;
}
.ll-spanBox .ll-sumt{
  font-size: 1rem;
  font-weight: unset;
  margin-left: 5px;
}
.ll-title{
  width: 70%;
  /* margin: auto; */
}
.ll-input{
  width: 25%;
  margin-left: 5px;
}
.ll-imgs{
  width: 25px;
  height: 25px;
}
</style>
